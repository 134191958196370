import GalvanLogo from '../../assets/images/Networks/galvan-logo.png'
import WinLogo from '../../assets/images/Networks/win.png'
import ElementLogo from '../../assets/images/Networks/element.png'
import GiveLogo from '../../assets/images/Networks/give.png'
import HyperLogo from '../../assets/images/Networks/hyper.png'
import GrowLogo from '../../assets/images/Networks/grow.png'
import LibertyLogo from '../../assets/images/Networks/liberty.png'
import GreenLogo from '../../assets/images/Networks/green.png'
import SwitchLogo from '../../assets/images/Networks/switch.png'
import ElevateLogo from '../../assets/images/Networks/elevate-logo.png'
import RevorideLogo from '../../assets/images/Networks/revoride.png'
import RlinkLogo from '../../assets/images/Networks/rlink.png'

export const networks = [
    'Win',
    'Switch',
    'Galvan',
    'Element',
    'Grow',
    'Liberty',
    'Give',
    'Elevate',
    'Hyper',
    'Green',
    'Revoride',
    'Rlink',
]

export const nodeImages = {
    'Win': WinLogo,
    'Switch': SwitchLogo,
    'Galvan': GalvanLogo,
    'Element': ElementLogo,
    'Grow': GrowLogo,
    'Liberty': LibertyLogo,
    'Give': GiveLogo,
    'Hyper': HyperLogo,
    'Green': GreenLogo,
    'Elevate': ElevateLogo,
    'Revoride': RevorideLogo,
    'Rlink': RlinkLogo,
  };
  