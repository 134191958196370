import React from 'react';
import Button from '@mui/material/Button';

function CustomButton({ backgroundColor, border, icon, children, padding, onClick, borderRadius, disabled, width, color}) {
  return (
    <Button 
      variant="contained"
      disabled={disabled} 
      size="medium" 
      type="submit"
      className='w-full text-[#fff] shadow-none flex justify-center sm:justify-evenly'
      style={{ backgroundColor, color, border, borderRadius, width, boxShadow: 'none', textTransform: 'none', padding, 
      display: 'flex!important', alignItems: 'center' }}
      onClick={onClick}
      >
      {icon && icon}
      {children}
    </Button>
  );
}

export default CustomButton;
