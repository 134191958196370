import React,{createContext, useState} from 'react'

export const Context = createContext()

function ContextWrapper({children}) {
    const [theme, setTheme] = useState(false)
  return (
    <Context.Provider value={{theme, setTheme}}>
        {children}
    </Context.Provider>
  )
}

export default ContextWrapper