// SignUpEmail.jsx
import React,{useState} from 'react';
import Logo from '../../assets/images/AISD-LOGO.png';
import CustomButton from '../common/CustomButton';
import EmailIcon from '@mui/icons-material/Email';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import LockIcon from '@mui/icons-material/Lock';
import Input from '../common/Input';
import PasswordField from '../common/PasswordField';
import axiosInstance from '../../axiosConfig';
import {useHistory} from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import BadgeIcon from '@mui/icons-material/Badge';
import { pushDataLayerEvent } from '../../utils/dataLayer';

function SignUpEmail() {
  const location = useLocation();
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const referralIdExists = location.pathname.split('/').length > 2;

    const [signupFormValues, setSignupFormValues] = useState({
        email:'',
        password:'',
        name:'',
        lastName:'',
        referralId: referralIdExists ? location.pathname.split('/')[2] : '',
      })
    
    const [error, setError] = useState('')
    const history = useHistory()
    const handleSubmit = (event) => {
        event.preventDefault();
       
        // Validate email
        if (!signupFormValues.email) {
            setEmailError('Email is required');
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signupFormValues.email)) {
            setEmailError('Invalid email format');
        }
    
        // Validate password
        if (!signupFormValues.password) {
          setPasswordError('Email is required');
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(signupFormValues.password)) {
          setPasswordError('Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character.');
        }

        const hasErrors = emailError || passwordError;
        if (hasErrors) {
            // Set error state to display a general error message
            setError('Please fix the errors in the form');
          } else {
            onSignup(); // Submit form
          }
      };

      const onSignup = async () => {
        try {
          const response = await axiosInstance.post('/auth/signup', signupFormValues);
    
          if (response.data.status) {
            history.push('/confirmMail');
          } else {
            setError(response.data.message);
          }
        } catch (error) {
          console.error('Signup error:', error);
          setError('An error occurred while signing up. Please try again.');
        }
        pushDataLayerEvent('create_account_button_clicked', {
          category: 'Button',
          action: 'Click',
          label: 'Create Account Button Clicked',
        });
      };

      if (!referralIdExists) {
        window.location.href = 'join/6684675647938ac2a0c0194d';
    }

    return (
        <div className='bg-grey-500 mx-auto text-center w-full lg:w-1/2 flex flex-col h-screen justify-center items-center my-14'>
            <div className='flex justify-center w-full mb-1 lg:mb-2'>
                <img className='w-20 lg:w-32' src={Logo} alt="AISD Server Logo" />
            </div>
            <div>
                <h2>Sign Up</h2>
                <h4 className='py-4'>Just a few things to get started</h4>
            </div>
            <div className='w-[90%] lg:w-[35%]'>
                <form onSubmit={handleSubmit}>
                    <Input
                        placeholder="Email"
                        label='Email'
                        icon={<EmailIcon className='text-black' />}
                        value={signupFormValues.email} 
                        onChange={(e) =>setSignupFormValues({...signupFormValues,email:e.target.value})}
                    />
                    <Input
                        placeholder="First Name"
                        label='First Name'
                        icon={<BadgeIcon className='text-black' />}
                        value={signupFormValues.name} 
                        onChange={(e) =>setSignupFormValues({...signupFormValues,name:e.target.value})}
                    />       
                    <Input
                        placeholder="Last Name"
                        label='Last Name'
                        icon={<BadgeIcon className='text-black' />}
                        value={signupFormValues.lastName} 
                        onChange={(e) =>setSignupFormValues({...signupFormValues,lastName:e.target.value})}
                    />                                     
                    {error && <div className='text-start text-red-500 text-xs'>{error}</div>}
                    <PasswordField
                        value={signupFormValues.password} 
                        onChange={(e) => {setSignupFormValues({...signupFormValues,password:e.target.value})}} 
                        label='Password'
                        placeholder="•••••••••"
                        icon={<LockIcon className='text-black' />}
                    />
                    {error && <div className='text-start text-red-500 text-xs'>{error}</div>}
                    <Input
                        disabled={referralIdExists}
                        placeholder=""
                        icon={<SupervisedUserCircleIcon className='text-black' />}
                        label='Referral Id'
                        value={signupFormValues.referralId}
                        onChange={(e) =>setSignupFormValues({...signupFormValues,referralId:e.target.value})}
                    />
                    <div>
                        <h4 className='py-2'>By creating this account you agree to our <br /><a href='/'><span className='text-[#F55937] font-[700] underline'>Privacy Policy</span></a></h4>
                    </div>
                    <div className='w-full py-4'>
                        <CustomButton backgroundColor="#F55937" borderRadius="10px" padding="12px 0" type="submit">
                            Create Account
                        </CustomButton>
                    </div>
                </form>
            </div>
            <div>
                <h4 className='py-2'>Already have an account? <span className='text-[#F55937] font-[700]'><a href='/login'>Log in</a></span></h4>
            </div>
        </div>
    );
}

export default SignUpEmail;
