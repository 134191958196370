import React, {Suspense, useEffect} from 'react'
import {
  Route,
  Switch 
} from 'react-router-dom'

// routes config
import routes from '../routes'
import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)




const TheMiddleware = ({children}) => {

  const history = useHistory();


  useEffect(() => {
    (async function () {
      const token = localStorage.getItem('token');
      if (token === null) {
        history.push("/login")
      }
    })();
  });

  return (
    children
  )
}

const TheContent = () => {
  return (
    <main className="c-main">
    
        <Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <TheMiddleware>
                        <route.component {...props} />
                      </TheMiddleware>
                  )}/>
              )
            })}
            <Redirect from="/" to="/dashboard"/>
          </Switch>
        </Suspense>
    </main>
  )
}

export default React.memo(TheContent)
