// Login.jsx
import React,{useEffect, useState} from 'react';
import Logo from '../../assets/images/AISD-LOGO.png';
import CustomButton from '../common/CustomButton.jsx';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Input from '../common/Input.jsx';
import PasswordField from '../common/PasswordField.jsx';
import axiosInstance from '../../axiosConfig.js';
import {useHistory} from 'react-router-dom'
import { setLocalStorage } from '../../utils/localstorage.js';
import { useUserStore } from '../../store/userStore.ts';
import { pushDataLayerEvent } from '../../utils/dataLayer';

function Login() {
  const [loginFormValues, setLoginFormValues] = useState({
    email: '',
    password: ''
  })
  const [error, setError] = useState('')
  const {setUser} = useUserStore()
  const history = useHistory()

  useEffect(() => {
    (async function () {
      const token = localStorage.getItem("token");
      if (token !== null) {
        history.push("/dashboard");
      }
    })();
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (loginFormValues.email !== "" && loginFormValues.password !== "") {
      onlogin();
    } else {
      setError("please fill the form completely");
    }
  };

  const onlogin = async () => {
    await axiosInstance
      .post("/auth/login", {
        email: loginFormValues?.email,
        password: loginFormValues?.password,
      })
      .then((response) => {
        if (response.data.status) {
          setLocalStorage("user", response.data.user[0]);
          setUser(response.data.user[0]);
          setLocalStorage("token", response.data.token);
          setLocalStorage("expiryTime", response.data.expiryTime);
          history.push("/dashboard");
        } else {
          setError(response.data.message);
        }
      });
      pushDataLayerEvent('login_button_clicked', {
        category: 'Button',
        action: 'Click',
        label: 'Login Button Clicked'
      });
  };


  return (
    <div className='bg-grey-500 mx-auto text-center w-full lg:w-1/2 flex flex-col h-screen justify-center items-center'>
      <div className='flex justify-center w-full mb-1 lg:mb-10'>
        <img className='w-20 lg:w-32' src={Logo} alt="AISD Server Logo" />
      </div>
      <div>
        <h2>Log in</h2>
        <h3 className='py-4'>Hello, welcome back</h3>
      </div>
      <div className='w-[90%] lg:w-[35%]'>
        <form onSubmit={handleSubmit}>
            <Input value={loginFormValues.email} onChange={(e) => {setLoginFormValues({...loginFormValues,email: e.target.value})}} placeholder="email" icon={<EmailIcon  className='text-black' />}/>
            <PasswordField value={loginFormValues.password} onChange={(e) => {setLoginFormValues({...loginFormValues,password: e.target.value})}} label='Password' placeholder="•••••••••" icon={<LockIcon  className='text-black' />}/>
            {error !== '' ? <div><p style={{color: 'red'}}>{error}</p></div> : <></>}
            <div className='w-full py-4'>
                <CustomButton backgroundColor="#F55937" 
                borderRadius="10px"
                padding="12px 0" >
                Login
                </CustomButton>
            </div>
        </form>
      </div>
      <div>
        <h4 className='py-2'>No account yet? <span className='text-[#F55937] font-[700]'><a href='/signupwithemail'>Create Account</a></span></h4>
      </div>
      <div>
        <h4 className='py-2'>Forgot password? <span className='text-[#F55937] font-[700]'> <a href='/recoverPassword'>Recover password here </a></span></h4>
      </div>
    </div>
  );
}

export default Login;
