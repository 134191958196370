import create from 'zustand'

interface IUser {
    _id: String,
    email: String,
    emailVerified: String,
    name: String,
    lastName: String,
    address: String,
    vat: String,
    country: String,
    dateOfBirth: Date,
    companyName: String
}

interface IUserAtom {
  user: IUser | null
  setUser: ({
    _id,
    email,
    emailVerified,
    name,
    lastName,
    address,
    vat,
    country,
    dateOfBirth,
    companyName
  }: IUser) => void
  
}

export const useUserStore = create<IUserAtom>(set => ({
  user: null,
  setUser: ({
    _id,
    email,
    emailVerified,
    name,
    lastName,
    address,
    vat,
    country,
    dateOfBirth,
    companyName
  }: IUser) => set({
    user:{
        _id,
        email,
        emailVerified,
        name,
        lastName,
        address,
        vat,
        country,
        dateOfBirth,
        companyName
    }
  }),
  
}))

