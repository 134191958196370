import axios from 'axios'

const apiUrl = process.env.REACT_APP_URL

 const axiosInstance = axios.create({
    baseURL: apiUrl, // Set your base URL
    // timeout: 5000, // Set request timeout (optional)
    headers: {
      'Content-Type': 'application/json', // Set default headers (optional)
    },
  });

  axiosInstance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error.response?.status === 401){
      localStorage.removeItem('token')
      window.location.href = "/";
      return;
    }
    return Promise.reject(error);
  });  
  export default axiosInstance
  