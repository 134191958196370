import { useEffect } from 'react';

const LogoutTimer = ({ timeoutInMS }) => {

  const clearSelectiveLocalStorage = () => {
    const cookieConsent = localStorage.getItem('cookie-consent');
    localStorage.clear();
    if (cookieConsent) {
      localStorage.setItem('cookie-consent', cookieConsent);
    }
  }
  useEffect(() => {
    let timer;
    
    const logout = () => {
      clearSelectiveLocalStorage()
    };

    const handleUserActivity = () => {
      clearTimeout(timer);
      timer = setTimeout(logout, timeoutInMS);
    };

    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keypress', handleUserActivity);
    document.addEventListener('touchstart', handleUserActivity);

    timer = setTimeout(logout, timeoutInMS);

    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keypress', handleUserActivity);
      document.removeEventListener('touchstart', handleUserActivity);
      clearTimeout(timer);
    };
  }, [timeoutInMS]);

  return null;
};

export default LogoutTimer;
