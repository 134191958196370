import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';

function Input({ label, showLabel, icon, p, mt, placeholder, ...props }) {
  return (
    <div className={`text-start ${mt ? mt : 'mt-2'}`}>
    {label && <label>{label}</label>}
        <div className={`bg-grey-200 rounded flex items-center ${p ? p : 'p-2'}`}>  
        {icon && (
            <InputAdornment position="start">
            {icon}
            </InputAdornment>
        )}
        <input
            className='bg-grey-200 text-black placeholder-black' 
            placeholder={placeholder}
            {...props}
        />
        </div>
    </div>
  );
}

export default Input;
