import React,{useState} from 'react'
import Logo from '../../assets/images/AISD-LOGO.png';
import CustomButton from '../common/CustomButton';
import { useParams, useHistory } from 'react-router-dom'
import LockIcon from '@mui/icons-material/Lock';
import PasswordField from '../common/PasswordField';
import axiosInstance from '../../axiosConfig';

function ChangePassword() {
    let{token} = useParams()
    const [error, setError] = useState('')
    const history = useHistory()
    const[changePasswordValues, setChangePasswordValues] = useState({
      password:'',
      reenterPassword: ''
    })
    const handleSubmit = async (event) => {
        event.preventDefault();
        if(changePasswordValues.password !== changePasswordValues.reenterPassword)
        {
          setError('passwords did not match')
          return 
        }
        await axiosInstance.post('/resetPassword',{
          password: changePasswordValues.password,
          token: token
        })
        .then((response) => {
          if(response.data.status)
          {
            history.push('/login')
          }
          else{
            setError(response.data.message)
          }
        })
        .catch((e) => {
          console.error('error while resetting password',e)
        })
    };

  return (
    <div className='bg-grey-500 mx-auto text-center w-full lg:w-1/2 flex flex-col h-screen justify-center items-center'>
      <div className='flex justify-center w-full mb-1 lg:mb-10'>
        <img className='w-20 lg:w-32' src={Logo} alt="AISD Server Logo" />
      </div>
      <div>
        <h2>Secure Your Account with a New Password</h2>
        <h4 className='py-4'>Please enter your new password below. Remember, <br/> it should be unique to you and hard for others to guess.</h4>
      </div>
      <div className='w-[90%] lg:w-[35%]'>
        <form onSubmit={handleSubmit}>
            <PasswordField value={changePasswordValues.password} onChange={(e) => {setChangePasswordValues({...changePasswordValues,password: e.target.value})}} label='New Password' placeholder="•••" icon={<LockIcon  className='text-black' />}/>
            <PasswordField value={changePasswordValues.reenterPassword} onChange={(e) => {setChangePasswordValues({...changePasswordValues,reenterPassword: e.target.value})}} label='Confirm Password' placeholder="•••" icon={<LockIcon  className='text-black' />}/>
            {error !== '' ? <div><p style={{color: 'red'}}>{error}</p></div> : <></>}
            <div className='w-full py-4'>
                <CustomButton backgroundColor="#4339F2"
                                borderRadius="10px"
                                padding="12px 0"
                >
                Set New Password
                </CustomButton>
            </div>
        </form>
      </div>
      <div>
        <h4 className='py-2'>By updating your password, you agree to<br/> our Terms of Service and Privacy Policy.</h4>
      </div>
    </div>
  )
}

export default ChangePassword