// PaymentSuccess.jsx
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import CustomButton from '../common/CustomButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function PaymentSuccess() {
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push('/dashboard');
    }, 3000);

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [history]);
  return (
    <div className='bg-grey-500 mx-auto text-center w-full lg:w-1/2 flex flex-col h-screen justify-center items-center'>
      <div className='flex justify-center w-full mb-10'>
        <CheckCircleOutlineIcon style={{ color: '#F55937', fontSize: '100px' }}/>
      </div>
      <div>
        <h2>Payment Completed, Congratulations! <br/>Nodes are Unlocked!</h2>
        <h3 className='py-3'> Your dashboard is set –  Tap into the power of seamless<br/> hosting and maximize your nodes performance today.</h3>
      </div>
      <div className='w-1/2 lg:w-1/4 py-4'>
        <CustomButton backgroundColor="#F55937" borderRadius="10px" padding="12px 0" >
          <a href='/dashboard'>Go to your dashboard</a>
        </CustomButton>
      </div>
    </div>
  );
}

export default PaymentSuccess;
