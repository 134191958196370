// ConfirmMail.jsx
import React from 'react';
import Illustration  from '../../assets/images/AISD-LOGO.png';

function ConfirmMail() {
  return (
    <div className='bg-grey-500 mx-auto text-center w-full lg:w-1/2 flex flex-col h-screen justify-center items-center'>
      <div className='flex justify-center w-full mb-10'>
        <img className='w-20 lg:w-32' src={Illustration} alt="AISD Server Logo" />
      </div>
      <div>
        <h2>Check your inbox and confirm your <br/>email to unlock your new dashboard.</h2>
        <h3 className='py-3'>Then, come back to this tab.</h3>
      </div>
    </div>
  );
}

export default ConfirmMail;
