import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function PasswordField({ label, icon,  placeholder, p, mt,  ...props }) {
    const [showPassword, setShowPassword] = useState(false)

  return (
    <div className={`text-start ${mt ? mt : 'mt-4'}`}>
        {label && <label>{label}</label>}
        <div className={`bg-grey-200 rounded flex items-center ${p ? p : 'p-2 bg-none'}`}>       
        {icon && (
            <InputAdornment position="start">
            {icon}
            </InputAdornment>
        )}
        <div className='flex items-center justify-between w-full'>
            <input
                id='password'
                name='password'
                type={`${showPassword ? 'text' : 'password'}`}
                className='bg-grey-200 text-black' 
                placeholder={placeholder}
                {...props}
            />
             <div onClick={() => setShowPassword((prevState) => !prevState)} className='cursor-pointer'>
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </div>

        </div>
        </div>
    </div>
  );
}

export default PasswordField;
