import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import TheContent from './TheContent';
import TheSidebar from './TheSidebar';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export default function TheLayout() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TheSidebar />
      <Box component="main" sx={{
        flexGrow: 1, p: 3, overflow: 'hidden!important', backgroundColor: '#2F292B',
      }}>
        <DrawerHeader />
        <TheContent/>
      </Box>
    </Box>
  );
}
