import './styles/style.css';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import SignUpEmail from './components/publicPages/SignUpEmail.jsx'; 
import Login from './components/publicPages/Login.jsx';
import ConfirmMail from './components/publicPages/ConfirmMail.jsx';
import Success from './components/publicPages/Success.jsx';
import ChangePassword from './components/publicPages/ChangePassword.jsx';
import RecoverPassword from './components/publicPages/RecoverPassword.jsx';
import ContextWrapper from './ContextWrapper';
import PaymentSuccess from './components/publicPages/PaymentSuccess.jsx';
import TheLayout from './containers/TheLayout.jsx';
import LogoutTimer from './components/common/LogoutTimer.jsx';
import CookieConsent from './components/common/CookieConsent.jsx';

function App() {
  const timeoutInMS = 30* 60 * 1000;
  return (
    <ContextWrapper>
       <LogoutTimer timeoutInMS={timeoutInMS} />
       <CookieConsent />
    <BrowserRouter>
      <Switch>
        <Route exact path="/join/:referralId" render={props => <SignUpEmail {...props}/>} />
        <Route exact path="/signupwithemail" render={props => <SignUpEmail {...props}/>}/>
        <Route exact path="/login" render={props => <Login {...props}/>}  />
        <Route exact path="/login-success" render={props => <Success {...props}/>} />
        <Route exact path="/payment-success" render={props => <PaymentSuccess {...props}/>} />
        <Route exact path='/confirmMail' render={props => <ConfirmMail {...props}/>} />
        <Route exact path='/changePassword/:token' render={props => <ChangePassword {...props}/>} />
        <Route exact path='/recoverPassword' render={props => <RecoverPassword {...props}/>} />
        <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
      </Switch>
    </BrowserRouter>
    </ContextWrapper>
  );
}

export default App;
