// utils/dataLayer.js

import TagManager from 'react-gtm-module';

/**
 * Pushes events to the data layer with the specified event name and additional data.
 * @param {string} eventName - The name of the event to push to the data layer.
 * @param {object} eventData - Additional data associated with the event.
 * @param {string} [category] - The category of the event.
 * @param {string} [action] - The action of the event.
 * @param {string} [label] - The label of the event.
 */
export const pushDataLayerEvent = (eventName, eventData, category, action, label) => {
  window.dataLayer.push({
    event: eventName,
    category: category,
    action: action,
    label: label,
    ...eventData,
  });
};

/**
 * Initializes the data layer with user-specific data when the component mounts.
 * @param {object} userData - The user data object containing details like _id, email, name, etc.
 */
export const initializeUserDataLayer = (userData) => {
  const dataLayer = {
    userId: userData._id,
    userEmail: userData.email,
    userName: `${userData.name} ${userData.lastName}`,
    address: userData.address,
    country: userData.country,
    vat: userData.vat,
    companyName: userData.companyName,
    dateOfBirth: userData.dateOfBirth,
    phoneNumber: userData.phoneNumber,
    termsAndConditionsAccepted: userData.termsAndConditionsAccepted,
  };
  TagManager.dataLayer({ dataLayer });
};
