import React,{useEffect, useState} from 'react';
import Logo from '../../assets/images/AISD-LOGO.png';
import CustomButton from '../common/CustomButton';
import Input from '../common/Input';
import EmailIcon from '@mui/icons-material/Email';
import axiosInstance from '../../axiosConfig';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { pushDataLayerEvent } from '../../utils/dataLayer';

function RecoverPassword() {

  const history = useHistory()

  useEffect(() => {
    (async function () {
      const token = localStorage.getItem("token");
      if (token !== null) {
        history.push("/dashboard");
      }
    })();
  });
    const [recoverPassFormValues, setRecoverPassFormValues] = useState({
        email: '',
      })
    const [error, setError] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const handleSubmit = (event) => {
        event.preventDefault();
        if(recoverPassFormValues.email !== '')
        {
          sendEmail()
        }
        else{
          setError('please fill the form completely')
        }
        pushDataLayerEvent('reset_pass_button_clicked', {
          category: 'Button',
          action: 'Click',
          label: 'Request Reset Password Button Clicked',
        });
    };

    const sendEmail = async () => {
        await axiosInstance.get('/resetPassword/sendEmail',{
          params:{
            email: recoverPassFormValues.email
          }
        })
        .then((response) => {
          if(response.data.status)
          {
            setError('')
            setSuccessMessage(response.data.message)
          }
          else{
            setSuccessMessage('')
            setError(response.data.message)
          }
        })
        .catch((e) => {
          console.error('error while sending email data', e)
        })
      }

  return (
    <div className='bg-grey-500 mx-auto text-center w-full lg:w-1/2 flex flex-col h-screen justify-center items-center'>
      <div className='flex justify-center w-full mb-1 lg:mb-10'>
        <img className='w-20 lg:w-32' src={Logo} alt="AISD Server Logo" />
      </div>
      <div>
        <h2>Forgot your password</h2>
        <h4 className='py-4'>Please enter the email address you'd like<br/> your password reset information set to</h4>
      </div>
      <div className='w-[90%] lg:w-[32%]'>
        <form onSubmit={handleSubmit}>
            <Input  placeholder="email" onChange={(e) => {setRecoverPassFormValues({...recoverPassFormValues,email: e.target.value})}} icon={<EmailIcon  className='text-black' />}/>
            {error !== '' ? <div><p style={{color: 'red'}}>{error}</p></div> : <></>}
            {successMessage !== '' ? <div><p className='underline' style={{color: 'red'}}>{successMessage}</p></div> : <></>}
            <div className='w-full py-4'>
                <CustomButton backgroundColor="#F55937"  borderRadius="10px"
                padding="12px 0">
                Request Reset Password
                </CustomButton>
            </div>
        </form>
      </div>
      <div>
        <h4 className='py-2'>No account yet? <span className='text-[#F55937] font-[700]'><a href='/signupwithemail'>Create Account</a></span></h4>
      </div>
      <div>
      <h4 className='py-2'>Already have an account? <span className='text-[#F55937] font-[700]'><a href='/login'>Log in</a></span></h4>
      </div>
    </div>
  )
}

export default RecoverPassword;