// Success.jsx
import React from 'react';
import Illustration  from '../../assets/images/AISD-LOGO.png';
import CustomButton from '../common/CustomButton';

function Success() {
  return (
    <div className='bg-grey-500 mx-auto text-center w-full lg:w-1/2 flex flex-col h-screen justify-center items-center'>
      <div className='flex justify-center w-full mb-10'>
        <img className='w-20 lg:w-32' src={Illustration} alt="AISD Server Logo" />
      </div>
      <div className='px-2 sm:px-0'>
        <h1 className='text-lg sm:text-3xl'>Your Gateway to Node Management is <br/><b>Unlocked!</b></h1>
        <h3 className='py-3 text-xs sm:text-2xl'> Your dashboard is set –  Tap into the power of seamless<br/> hosting and maximize your nodes’ performance today.</h3>
      </div>
      <div className='w-1/2 lg:w-1/4 py-4'>
        <CustomButton backgroundColor="#F55937" borderRadius="10px" padding="12px 0">
          <a href='/dashboard'>Start Hosting</a>
        </CustomButton>
      </div>
    </div>
  );
}

export default Success;
